@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;
}